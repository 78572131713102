import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';

const SendEmail = ({
	open,
	onClose,
	emailValue,
	onChangeEditEmail,
	onsubmitEmail,
}) => {
	const onSubmit = () => {
		onsubmitEmail();
		onClose()
	};
	return (
		<div>
			<Dialog
				open={open}
				onClose={onClose}
				maxWidth='xs'
				fullWidth
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title' align='center'>Send latest message as email.</DialogTitle>
				<DialogContent>
					<div style={{ padding: '20px 0px' }}>
						<TextField
							fullWidth
							variant='filled'
							onChange={onChangeEditEmail}
							label='Email'
							defaultValue={emailValue}
						/>
					</div>
				</DialogContent>
				<DialogActions style={{justifyContent:'center'}}>
					<Button
						onClick={onClose}
						variant='outlined'
						style={{ border: '1px solid #FBC112', color: '#FBC112' }}>
						Cancel
					</Button>
					<Button
						onClick={onSubmit}
						autoFocus
						variant='contained'
						style={{ background: '#FBC112', color: '#FFF' }}>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SendEmail;
