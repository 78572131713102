import React, { useState, useEffect } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const ChatAnimation = ({ text, delay, getValue, getLoading }) => {
	const [displayedText, setDisplayedText] = useState('');

	useEffect(() => {
		const words = text.split(' ');
		let currentIndex = 0;
		const interval = setInterval(() => {
			if (currentIndex < words.length) {
				getLoading(true);
				setDisplayedText(words.slice(0, currentIndex + 1).join(' '));
				getValue(words.slice(0, currentIndex + 1).join(' '));
				currentIndex++;
			} else {
				clearInterval(interval);
				getLoading(false);
			}
		}, delay);
		return () => clearInterval(interval);
		
	}, [text, delay]);
	return <ReactMarkdown>{displayedText}</ReactMarkdown>;
};
export default ChatAnimation;
