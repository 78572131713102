import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import JobGPT from './JobGPT';
import Toaster from './core/toaster';

function App() {
	return (
		<div>
			<Toaster/>
			<Router>
				<Routes>
					<Route path='/' element={<JobGPT />} />
				</Routes>
			</Router>
		</div>
	);
}
export default App;
